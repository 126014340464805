import React, { Component } from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";



export default function Download() {
    const initData = {
        heading: stringsTranslate(words.words.app_available_for_all),
        headingText: "",
        headingTexttwo: "",
        headingSlug: stringsTranslate(words.words.available_on),
        playImg: "/img/google-play-black.png",
        appleImg: "/img/app-store-black.png",
    }
        return (
            <section className="section download-area overlay-dark ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-8">
                        {/* Download Text */}
                        <div className="download-text text-center">
                        <h2 className="text-white">{initData.heading}</h2>
                        <p className="text-white my-3 d-none d-sm-block">{initData.headingText}</p>
                        <p className="text-white my-3 d-block d-sm-none">{initData.headingTexttwo}</p>
                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex justify-content-center">
                            <a href="https://play.google.com/store/apps/details?id=com.lesoptijd.lesoptijd">
                            <img src={initData.playImg} alt="" />
                            </a>
                            <a href="https://apps.apple.com/us/app/lesoptijd/id1599937933?platform=iphone">
                            <img src={initData.appleImg} alt="" />
                            </a>
                        </div>
                        <span className="d-inline-block text-white fw-3 font-italic mt-3">{initData.headingSlug}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
