export const words = {
    words: {
        home: "home",
        features: "features",
        plans: "plans",
        feature_driving_school: "feature_driving_school",
        feature_driving_instructor: "feature_driving_instructor",
        feature_student: "feature_student",
        app_features: "app_features",
        signout: "signout",
        signin: "signin",
        dashboard_yes_title: "dashboard_yes_title",
        go_download_the_app: "go_download_the_app",
        driving_instructor: "driving_instructor",
        manage_students: "manage_students",
        manage_appointments: "manage_appointments",
        multiple_languages: "multiple_languages",
        coming_soon: "coming_soon",
        give_feedback: "give_feedback",
        manage_finances: "manage_finances",
        chatting: "chatting",
        smart_notifications: "smart_notifications",
        student_progress: "student_progress",
        driving_school: "driving_school",
        manage_staff: "manage_staff",
        manage_products: "manage_products",
        manage_packages: "manage_packages",
        post_vacancies: "post_vacancies",
        ads_free: "ads_free",
        coming_to_the_top_list: "coming_to_the_top_list",
        review_up: "review_up",
        search_driving_school: "search_driving_school",
        progress_overview: "progress_overview",
        student: "student",
        register_at_a_driving_school: "register_at_a_driving_school",
        the_driving_managment_tool: "the_driving_managment_tool",
        easy_to_use: "easy_to_use",
        quick_to_set_up: "quick_to_set_up",
        download_now: "download_now",
        make_your_life_easy: "make_your_life_easy",
        easily_manage_everything_instructors_students: "easily_manage_everything_instructors_students",
        do_everything_in_one_place: "do_everything_in_one_place",
        life_is_easy_again: "life_is_easy_again",
        manage_your_appointment_from_one_place: "manage_your_appointment_from_one_place",
        your_easy_way_to_deal_with_student: "your_easy_way_to_deal_with_student",
        all_in_one_yeeees: "all_in_one_yeeees",
        complete_learning_path_in_your_hands: "complete_learning_path_in_your_hands",
        all_the_tools_that_you_need_to_get_you_on_the_road_by_your_self: "all_the_tools_that_you_need_to_get_you_on_the_road_by_your_self",
        sign_up: "sign_up",
        per_month: "per_month",
        select_basic_plan: "select_basic_plan",
        basic_package: "basic_package",
        premium_package: "premium_package",
        select_premium_plan: "select_premium_plan",
        email_address: "email_address",
        password: "password",
        sign_up_upper_case: "sign_up_upper_case",
        or: "or",
        forget_password_upper_case: "forget_password_upper_case",
        you_are: "you_are",
        im_driving_school: "im_driving_school",
        im_student: "im_student",
        driving_shool_name: "driving_shool_name",
        owner_name: "owner_name",
        first_name: "first_name",
        last_name: "last_name",
        mobile_number: "mobile_number",
        contact_details: "contact_details",
        street: "street",
        house_number: "house_number",
        additional: "additional",
        postalcode: "postalcode",
        city: "city",
        registration_number_kvk: "registration_number_kvk",
        register: "register",
        please_fill_in_your_driving_school_name: "please_fill_in_your_driving_school_name",
        please_fill_in_your_first_name: "please_fill_in_your_first_name",
        please_fill_in_your_last_name: "please_fill_in_your_last_name",
        please_fill_in_your_owner_name: "please_fill_in_your_owner_name",
        please_fill_in_your_mobile_number: "please_fill_in_your_mobile_number",
        please_fill_in_your_email_addres: "please_fill_in_your_email_addres",
        please_fill_in_your_password: "please_fill_in_your_password",
        please_fill_in_your_street: "please_fill_in_your_street",
        please_fill_in_your_house_number: "please_fill_in_your_house_number",
        please_fill_in_your_postal_code: "please_fill_in_your_postal_code",
        please_fill_in_your_city: "please_fill_in_your_city",
        please_fill_in_your_registration_number: "please_fill_in_your_registration_number",
        verifiy_your_email_address: "verifiy_your_email_address",
        your_account_has_not_been_verified_yet_please_verify_your_account_again: "your_account_has_not_been_verified_yet_please_verify_your_account_again",
        check_again: "check_again",
        send_me_link_again: "send_me_link_again",
        language: "language",
        english: "english",
        arabic: "arabic",
        dutch: "dutch",
        manage_announcement: "manage_announcement",
        overview_of_feedbacks: "overview_of_feedbacks",
        contact: "contact",
        account: "account",
        app_screen: "app_screen",
        available_on: "available_on",
        explore_features: "explore_features",
        notifications: "notifications",
        calendar: "calendar",
        feedback: "feedback",
        progress: "progress",
        management_system: "management_system",
        work_faster: "work_faster",
        make_your_device_manage: "make_your_device_manage",
        how_lesoptijd_works: "how_lesoptijd_works",
        go_through_these_steps: "go_through_these_steps",
        install_app: "install_app",
        setup_your_profile: "setup_your_profile",
        enjoy_features: "enjoy_features",
        simple_interface: "simple_interface",
        unlock_full_power: "unlock_full_power",
        our_partners: "our_partners",
        app_available_for_all: "app_available_for_all",
        user_friendly_and_comprehensive: "user_friendly_and_comprehensive",
        learn_more: "learn_more",
        know_more: "know_more",




        words:"words"
    },
    nl: {
        home: "Startpagina",
        features: "Features",
        plans: "Plannen",
        feature_driving_school: "Functie rijschool",
        feature_driving_instructor: "Functie rij-instructeur",
        feature_student: "functie student",
        app_features: "App functies",
        signout: "Afmelden",
        signin: "Sign in",
        dashboard_yes_title: "Ja 😁, uw account is aangemaakt 🎉🎉",
        go_download_the_app: "Ga de app downloaden",
        driving_instructor: "Rijinstructeur",
        manage_students: "Studenten beheren",
        manage_appointments: "Afspraken beheren",
        multiple_languages: "Meerdere talen",
        coming_soon: "Binnenkort beschikbaar",
        give_feedback: "Feedback geven aan leerling",
        manage_finances: "Financiën beheren",
        chatting: "Chatten",
        smart_notifications: "Slimme notificaties",
        student_progress: "Voortgang van de student",
        driving_school: "Rijschool",
        manage_staff: "Rij-instructeurs beheren",
        manage_products: "Producten beheren",
        manage_packages: "Rijlespakketten beheren",
        post_vacancies: "Plaats vacatures op onze website",
        ads_free: "Zonder advertenties",
        Coming_to_the_top_list: "Komt bovenaan de lijst met scholen",
        review_up: "Review omhoog",
        search_driving_school: "Zoek rijschool",
        progress_overview: "Voortgangsoverzicht",
        student: "Student",
        the_driving_managment_tool: "de beheertool voor rijlessen voor rijschoolhouders",
        every_thing_you_need_for_your_driving_school_yes: "Alles voor je rijschool. Ja",
        easy_to_use: "Makkelijk te gebruiken",
        quick_to_set_up: "Snel in te stellen",
        download_now: "Download nu",
        make_your_life_easy: "Maak je leven makkelijk.",
        easily_manage_everything_instructors_students: "Beheer alles eenvoudig (docenten en studenten)",
        do_everything_in_one_place: "Doe alles op één plek",
        life_is_easy_again: "Het leven is weer makkelijk",
        manage_your_appointment_from_one_place: "Beheer uw afspraak vanaf één plek",
        your_easy_way_to_deal_with_student: "De beste manier om met uw studenten om te gaan",
        all_in_one_yeeees: "Alles in één Yeeeees",
        complete_learning_path_in_your_hands: "Compleet leertraject in jouw handen",
        all_the_tools_that_you_need_to_get_you_on_the_road_by_your_self: "Alle tools die je nodig hebt om je zelf op weg te helpen",
        sign_up: "Aanmelden",
        per_month: "Per maand",
        select_basic_plan: "Selecteer Basisplan",
        basic_package: "Basispakket",
        premium_package: "Premium Pakket",
        select_premium_plan: "Selecteer Premium-abonnement",
        email_address: "E-mailadres",
        password: "Wachtwoord",
        sign_up_upper_case: "AANMELDEN",
        or: "OF",
        forget_password_upper_case: "WACHTWOORD VERGETEN",
        you_are: "Jij bent:",
        im_driving_school: "Ik ben rijschool",
        im_student: "Ik ben student",
        driving_shool_name: "Naam rijschool",
        owner_name: "Eigenaar naam",
        first_name: "Voornaam",
        last_name: "Achternaam",
        mobile_number: "Mobiel nummer",
        contact: "Contact",
        contact_details: "Contactgegevens",
        street: "Straat",
        house_number: "Huisnummer",
        additional: "Toevoeging",
        postalcode: "Postcode",
        city: "Stad",
        registration_number_kvk: "Registratienummer (KVK)",
        register: "Registreer",
        please_fill_in_your_driving_school_name: "Vul de naam van je rijschool in",
        please_fill_in_your_first_name: "Vul alstublieft uw voornaam in",
        please_fill_in_your_last_name: "Vul alstublieft uw achternaam in",
        please_fill_in_your_owner_name: "Vul uw eigenaarsnaam in",
        please_fill_in_your_mobile_number: "Vul uw mobiele nummer in",
        please_fill_in_your_email_addres: "Vul uw e-mailadres in",
        please_fill_in_your_password: "Vul uw wachtwoord in",
        please_fill_in_your_street: "Vul alstublieft uw straat in",
        please_fill_in_your_house_number: "Vul alstublieft uw huisnummer in",
        please_fill_in_your_postal_code: "Vul uw postcode in",
        please_fill_in_your_city: "Vul uw stad in",
        please_fill_in_your_registration_number: "Vul uw registratienummer in",
        verifiy_your_email_address: "Verifieer je e-mailadres",
        your_account_has_not_been_verified_yet_please_verify_your_account_again: "Uw account is nog niet geverifieerd. Verifieer uw account opnieuw",
        check_again: "Controleer nog eens",
        send_me_link_again: "Stuur me nogmaals een link",
        language: "Taal",
        english: "Engels",
        arabic: "Arabisch",
        dutch: "Nederlands",
        manage_announcement: "Aankondigingen beheren",
        overview_of_feedbacks: "Overzicht van feedback",
        account: "Account",
        app_screen: "App-schermen",
        available_on: "* Beschikbaar op iPhone, iPad en alle Android-apparaten",
        explore_features: "Functies verkennen",
        notifications: "Notificaties",
        calendar: "Agenda",
        feedback: "Feedback geven",
        progress: "Les voortgang",
        management_system: "Beheersysteem",
        work_faster: "Werk sneller met krachtige tools.",
        make_your_device_manage: "De app die alles voor u beheert",
        how_lesoptijd_works: "Hoe LesOpTijd werkt?",
        go_through_these_steps: "Loop deze stappen door",
        install_app: "Installeer de app",
        setup_your_profile: "Stel je profiel in",
        enjoy_features: "Geniet van de functies!",
        simple_interface: "Overzichtelijke en gebruiksvriendelijke interface",
        unlock_full_power: "Gebruik LesOpTijd op volle kracht",
        our_partners: "Onze partners",
        app_available_for_all: "LesOpTijd is beschikbaar voor alle apparaten",
        user_friendly_and_comprehensive: "Gebruiksvriendelijke en uitgebreide functies voor zowel rij-instructeurs, rijscholen en leerlingen",
        learn_more: "Lees verder",
        know_more: "Meer weten",

        words:"wordsNL"

    },
    ar:  {
        home: "الصفحة الرئيسية",
        features: "الميزات",
        plans: "الخطط",
        feature_driving_school: "ميزة مدرسة لتعليم قيادة السيارات",
        feature_driving_instructor: "ميزة مدرب القيادة",
        feature_student: "طالب مميز",
        app_features: "ميزات التطبيق",
        signout: "تسجيل خروج",
        signin: "تسجيل الدخول",
        dashboard_yes_title: "نعم 😁 ، تم إنشاء حسابك 🎉🎉",
        go_download_the_app: "اذهب لتحميل التطبيق",
        driving_instructor: "مدرب القيادة",
        manage_students: "إدارة الطلاب",
        manage_appointments: "إدارة المواعيد",
        multiple_languages: "لغات متعددة",
        coming_soon: "قريبا",
        give_feedback: "قدم ملاحظات للطالب",
        manage_finances: "يدير امور ماليه",
        chatting: "الدردشة",
        smart_notifications: "الإشعارات الذكية",
        student_progress: "تقدم الطالب",
        driving_school: "مدرسة لتعليم القيادة",
        manage_staff: "إدارة مدربي القيادة",
        manage_products: "إدارة المنتجات",
        manage_packages: "إدارة حزم دروس القيادة",
        post_vacancies: "نشر الوظائف الشاغرة على موقعنا",
        ads_free: "خالية من الإعلانات",
        Coming_to_the_top_list: "يأتي على رأس قائمة المدارس",
        review_up: "مراجعة ما يصل",
        search_driving_school: "ابحث عن مدرسة لتعليم قيادة السيارات",
        progress_overview: "نظرة عامة على التقدم",
        student: "طالب",
        register_at_a_driving_school: "سجل في مدرسة لتعليم قيادة السيارات",
        every_thing_you_need_for_your_driving_school_yes: "أداة إدارة دروس القيادة لأصحاب مدارس القيادة",
        easy_to_use: "سهل الاستخدام",
        quick_to_set_up: "سريع لاقامة",
        download_now: "التحميل الان",
        make_your_life_easy: "اجعل حياتك سهلة.",
        easily_manage_everything_instructors_students: "إدارة كل شيء بسهولة (المدرسون والطلاب)",
        do_everything_in_one_place: "افعل كل شيء في مكان واحد",
        life_is_easy_again: "الحياة سهلة مرة أخرى",
        manage_your_appointment_from_one_place: "إدارة موعدك من مكان واحد",
        your_easy_way_to_deal_with_student: "طريقتك السهلة للتعامل مع الطالب",
        all_in_one_yeeees: "الكل في واحد Yeeees",
        complete_learning_path_in_your_hands: "أكمل مسار التعلم بين يديك",
        all_the_tools_that_you_need_to_get_you_on_the_road_by_your_self: "كل الأدوات التي تحتاجها لتنقلك على الطريق بنفسك",
        sign_up: "اشتراك",
        per_month: "كل شهر",
        select_basic_plan: "حدد الخطة الأساسية",
        basic_package: "الحزمة الأساسية",
        premium_package: "حزمة قسط",
        select_premium_plan: "حدد خطة بريميوم",
        email_address: "عنوان بريد الكتروني",
        password: "كلمة المرور",
        sign_up_upper_case: "اشتراك",
        or: "أو",
        forget_password_upper_case: "هل نسيت كلمة السر",
        you_are: "أنت:",
        im_driving_school: "أنا مدرسة القيادة",
        im_student: "انا طالب",
        driving_shool_name: "اسم المدرسة المدرسية للقيادة",
        owner_name: "اسم المالك",
        first_name: "الاسم الأول",
        last_name: "اسم العائلة",
        mobile_number: "رقم الجوال",
        contact_details: "تفاصيل الاتصال",
        street: "شارع",
        house_number: "رقم المنزل",
        additional: "إضافية",
        postalcode: "الرمز البريدي",
        city: "المدينة",
        registration_number_kvk: "رقم التسجيل (KVK)",
        register: "تسجيل",
        please_fill_in_your_driving_school_name: "يرجى ملء اسم مدرسة القيادة الخاصة بك",
        please_fill_in_your_first_name: "الرجاء إدخال اسمك الأول",
        please_fill_in_your_last_name: "الرجاء إدخال اسمك الأخير",
        please_fill_in_your_owner_name: "الرجاء كتابة اسم المالك الخاص بك",
        please_fill_in_your_mobile_number: "يرجى ملء رقم هاتفك المحمول",
        please_fill_in_your_email_addres: "يرجى ملء عناوين البريد الإلكتروني الخاص بك",
        please_fill_in_your_password: "الرجاء إدخال كلمة المرور الخاصة بك",
        please_fill_in_your_street: "من فضلك املأ شارعك",
        please_fill_in_your_house_number: "يرجى ملء رقم منزلك",
        please_fill_in_your_postal_code: "يرجى ملء الرمز البريدي الخاص بك",
        please_fill_in_your_city: "يرجى ملء مدينتك",
        please_fill_in_your_registration_number: "يرجى ملء رقم التسجيل الخاص بك",
        verifiy_your_email_address: "تحقق من عنوان بريدك الإلكتروني",
        your_account_has_not_been_verified_yet_please_verify_your_account_again: "لم يتم التحقق من حسابك حتى الآن. يرجى التحقق من حسابك مرة أخرى",
        check_again: "تحقق مرة اخرى",
        send_me_link_again: "أرسل لي الارتباط مرة أخرى",
        language: "اللغة",
        english: "إنكليزي",
        arabic: "عربي",
        dutch: "هولندي",
        manage_announcement: "إدارة الإعلانات",
        overview_of_feedbacks: "نظرة عامة على الملاحظات",
        contact: "معلومات الاتصال",
        account: "حساب",
        app_screen: "شاشات التطبيق",
        available_on: "* Android وجميع أجهزة iPhone و iPad متوفر على أجهزة ",
        explore_features: "اكتشف الميزات",
        notifications: "الإشعارات",
        calendar: "التقويم",
        feedback: "تقييم",
        progress: "تقدم الدروس",
        management_system: "نظام إدارة",
        work_faster: "اعمل بشكل أسرع باستخدام الأدوات القوية.",
        make_your_device_manage: "التطبيق الذي يدير كل شيء من أجلك",
        how_lesoptijd_works: "كيف يعمل التطبيق؟",
        go_through_these_steps: "اتبع هذه الخطوات",
        install_app: "قم بتثبيت التطبيق",
        setup_your_profile: "قم بإعداد ملف التعريف الخاص بك",
        enjoy_features: "استمتع بالميزات!",
        simple_interface: "واجهة واضحة وسهلة الاستخدام",
        unlock_full_power: "استخدم Lesoptijd بكامل طاقته",
        our_partners: "شركاؤنا",
        app_available_for_all: " متاح لجميع الأجهزة LesOpTijd",
        user_friendly_and_comprehensive: "ميزات سهلة الاستخدام وشاملة لمدربي القيادة ومدارس القيادة والطلاب على حدٍ سواء",
        learn_more: "تعرف أكثر",
        know_more: "تعرف أكثر",

        words:"wordsAR"
    },

    en: {
        home: "Home",
        features: "Features",
        plans: "Plans",
        feature_driving_school: "Feature driving school",
        feature_driving_instructor: "Feature driving school",
        feature_student: "Feature student",
        app_features: "App features",
        signout: "Sign out",
        signin: "Sign in",
        dashboard_yes_title: "Yes 😁, your account has been made 🎉🎉",
        go_download_the_app: "Go download the app",
        driving_instructor: "Driving instructor",
        manage_students: "Manage students",
        manage_appointments: "Manage appointments",
        multiple_languages: "Multiple languages",
        coming_soon: "Coming Soon",
        give_feedback: "Give feedback to student",
        manage_finances: "Manage finances",
        chatting: "Chatting",
        smart_notifications: "Smart notifications",
        student_progress: "Student progress",
        driving_school: "Driving school",
        manage_staff: "Manage driving instructors",
        manage_products: "Manage products",
        manage_packages: "Manage driving lessons packages",
        post_vacancies: "Post vacancies on our website",
        ads_free: "Ads-free",
        coming_to_the_top_list: "Coming to the top of the list of schools",
        review_up: "Review up",
        search_driving_school: "Search driving school",
        progress_overview: "Progress overview",
        student: "Student",
        register_at_a_driving_school: "Register at a driving school",
        the_driving_managment_tool: "the driving lessons management tool for driving school holders",
        easy_to_use: "Easy to use",
        quick_to_set_up: "Quick to set up",
        download_now: "Download Now",
        make_your_life_easy: "Make your life easy.",
        easily_manage_everything_instructors_students: "Easily manage everything (Instructors & students)",
        do_everything_in_one_place: "Do everything in one place",
        life_is_easy_again: "Life is easy again",
        manage_your_appointment_from_one_place: "Manage your appointment from one place",
        your_easy_way_to_deal_with_student: "Your easy way to deal with student",
        all_in_one_yeeees: "All in one Yeeees",
        complete_learning_path_in_your_hands: "Complete learning path in your hands",
        all_the_tools_that_you_need_to_get_you_on_the_road_by_your_self: "All the tools that you need to get you on the road by yourself",
        sign_up: "Sign up",
        per_month: "Per month",
        select_basic_plan: "Select Basic Plan",
        basic_package: "Basic Package",
        premium_package: "Premium Package",
        select_premium_plan: "Select Premium Plan",
        email_address: "Email address",
        password: "Password",
        sign_up_upper_case: "SIGN UP",
        or: "OR",
        forget_password_upper_case: "FORGOT PASSWORD",
        you_are: "You are:",
        im_driving_school: "I'm driving school",
        im_student: "I'm student",
        driving_shool_name: "Driving shool name",
        owner_name: "Owner name",
        first_name: "First name",
        last_name: "Last name",
        mobile_number: "Mobile number",
        contact_details: "Contact Details",
        street: "Street",
        house_number: "House number",
        additional: "Additional",
        postalcode: "Postalcode",
        city: "City",
        registration_number_kvk: "Registration number (KVK)",
        register: "Register",
        please_fill_in_your_driving_school_name: "Please fill in your driving school name",
        please_fill_in_your_first_name: "Please fill in your first name",
        please_fill_in_your_last_name: "Please fill in your last name",
        please_fill_in_your_owner_name: "Please fill in your owner name",
        please_fill_in_your_mobile_number: "Please fill in your mobile number",
        please_fill_in_your_email_addres: "Please fill in your email addres",
        please_fill_in_your_password: "Please fill in your password",
        please_fill_in_your_street: "Please fill in your street",
        please_fill_in_your_house_number: "Please fill in your house number",
        please_fill_in_your_postal_code: "Please fill in your postal code",
        please_fill_in_your_city: "Please fill in your city",
        please_fill_in_your_registration_number: "Please fill in your registration number",
        verifiy_your_email_address: "Verify your email address",
        your_account_has_not_been_verified_yet_please_verify_your_account_again: "Your account has not been verified yet. Please verify your account again",
        check_again: "Check again",
        send_me_link_again: "Send me link again",
        language: "Language",
        english: "English",
        arabic: "Arabic",
        dutch: "Dutch",
        manage_announcement: "Manage announcements",
        overview_of_feedbacks: "Overview of feedbacks",
        contact: "Contact",
        account: "Account",
        app_screen: "App screens",
        available_on: "* Available on iPhone, iPad and all Android devices",
        explore_features: "Explore features",
        notifications: "Notifications",
        calendar: "Agenda",
        feedback: "To give feedback",
        progress: "Lesson progress",
        management_system: "Management system",
        work_faster: "Work faster with powerful tools.",
        make_your_device_manage: "The app that manages everything for you",
        how_lesoptijd_works: "How sApp works?",
        go_through_these_steps: "Go through these steps",
        install_app: "Install the App",
        setup_your_profile: "Setup your profile",
        enjoy_features: "Enjoy the features!",
        simple_interface: "Clear and user-friendly interface",
        unlock_full_power: "Use LesOpTijd with full power",
        our_partners: "Our partners",
        app_available_for_all: "LesOpTijd is available for all devices",
        user_friendly_and_comprehensive: "User-friendly and comprehensive features for driving instructors, driving schools and students alike",
        learn_more: "Learn more",
        know_more: "Know more",

        words:"wordsEN"
    },
}
