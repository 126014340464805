import React, { Component } from 'react';
import ContactForm from './ContactForm';
import axios from 'axios';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {GiScooter, MdOutlinePedalBike} from "react-icons/all";


export default function ContactSection() {
    const initData = {
        "heading": stringsTranslate(words.words.contact),
        "headingText": "",
        "headingTexttwo": "",
        "content": "",
        "iconList": [
            {
                "id": 1,
                "iconClass": "fas fa-home",
                "text": "Tt. Vasumweg 292, 1033 SJ Amsterdam"
            },
            {
                "id": 2,
                "iconClass": "fas fa-phone-alt",
                "text": "+3 162 448 996"
            },
            {
                "id": 3,
                "iconClass": "fas fa-envelope",
                "text": "info@lesoptijd.nl"
            }
        ]
    }

        return (
            <section id="contact" className="contact-area bg-gray ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-md-5 order-1">
                        {/* Contact Us */}
                        <div className="contact-us">
                        <p className="mb-3">{initData.content}</p>
                            <ul>
                                {initData.iconList.map((item, idx) => {
                                    return(
                                        <li key={`ci_${idx}`} className="py-2">
                                            <a className="media" href="/#">
                                                <div className="social-icon mr-3">
                                                    <i className={item.iconClass} />
                                                </div>
                                                <span className="media-body align-self-center">{item.text}</span>
                                            </a>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 pt-4 pt-md-0 order-0 ">
                        <div className="section-heading text-center mt-5 pt-2">
                            <h2 className="text-capitalize">{initData.heading}</h2>
                            <p className="d-none d-sm-block mt-4">{initData.headingText}</p>
                            <p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
