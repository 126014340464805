import Header from "../components/HeaderSection/Header";
import {DisclaimerComponent} from "../components/Disclaimer/DisclaimerComponent";
import DownloadSection from "../components/DownloadSection/Download";
import ContactSection from "../components/ContactSection/Contact";
import FooterSection from "../components/FooterSection/Footer";
import React from "react";
import HeroSection from "../components/HeroSection/HeroTwo";
import ComingSoon from "../components/InnerSection/ComingSoon";
import GreenStart from "../components/InnerSection/GreenStart";

export default function Disclaimer() {
    return (
        <div>
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up" />
            </div>
            {/*====== Scroll To Top Area End ======*/}
            <div className="main">
                <GreenStart></GreenStart>
                <DisclaimerComponent />
                <DownloadSection />
                <ContactSection />
                <FooterSection />
            </div>
        </div>
    );
}
