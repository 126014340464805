import React from 'react';

// importing MyRouts where we located all of our theme
import MyRouts from './routers/routes'
import Header from "./components/HeaderSection/Header";
import {saveDataLocalStorage} from "./uitls/LocalStorageFunctions";
import {appLocalStoargeValues} from "./config/constants";

export default function App() {

    function switchLangauge(language){
        saveDataLocalStorage(appLocalStoargeValues.language, language)
        window.location.reload();
    }

  return (
    <div>
        <Header switchLangauge={(language)=> switchLangauge(language)} imageData={"/lesoptijd/logo_white.svg"}/>
      <MyRouts/>
    </div>
  );
}
