import React, { Component } from 'react';
import axios from 'axios';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {
    BsAppIndicator,
    BsGlobe,
    FiSettings,
    GiProgression,
    IoCalendarOutline,
    MdOutlineFeedback
} from "react-icons/all";


export default function Work() {
    const initData = {
        "heading": stringsTranslate(words.words.how_lesoptijd_works),
        "headingText": stringsTranslate(words.words.go_through_these_steps),
        "workData": [
            {
                "id": 1,
                "image": "/img/work_thumb_1.png",
                "title": stringsTranslate(words.words.install_app),
                "content": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?"
            },
            {
                "id": 2,
                "image": "/img/work_thumb_2.png",
                "title": stringsTranslate(words.words.setup_your_profile),
                "content": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?"
            },
            {
                "id": 3,
                "image": "/img/work_thumb_3.png",
                "title": stringsTranslate(words.words.enjoy_features),
                "content": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eius saepe, voluptates quis enim incidunt obcaecati?"
            }
        ]
    }

        return (
            <section className="section work-area bg-overlay overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-lg-6">
                            {/* Work Content */}
                            <div className="work-content text-center">
                                <h2 className="text-white">{initData.heading}</h2>
                                <p className="text-white my-3 mt-sm-4 mb-sm-5">{initData.headingText}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {initData.workData.map((item, idx) => {
                            return(
                                <div key={`w_${idx}`} className="col-12 col-md-4">
                                {/* Single Work */}
                                <div className="single-work text-center p-3">
                                    {/* Work Icon */}
                                    <div className="work-icon">
                                        <img className="avatar-md" src={item.image} alt="" />
                                    </div>
                                    <h3 className="text-white py-3">{item.title}</h3>
                                    {/*<p className="text-white">{item.content}</p>*/}
                                </div>
                            </div>
                            );
                        })}
                    </div>
                </div>
            </section>
        );
    }
