import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";



export default function HeroSection() {
    const initData = {
        heading: "LesOpTijd",
        headingTwo: stringsTranslate(words.words.the_driving_managment_tool),
        content:  stringsTranslate(words.words.user_friendly_and_comprehensive),
        headingSlug:  stringsTranslate(words.words.available_on),
        playImg: "/img/google-play-black.png",
        appleImg: "/img/app-store-black.png",
        heroThumb: "/img/extra/lesoptijd_mobiel_nobg.png"
    }
        return (
            <section id="home" className="section welcome-area bg-overlay overflow-hidden d-flex align-items-center">
                <div className="container">
                    <div className="row align-items-center">
                    <div className="col-12 col-md-6 order-2 order-md-1">
                        {/* Welcome Thumb */}
                        <div className="mx-auto">
                        <img src={initData.heroThumb} alt=""  width={900}/>
                        </div>
                    </div>
                    {/* Welcome Intro Start */}
                    <div className="col-12 col-md-6 order-1 order-md-2">
                        <div className="welcome-intro px-0 px-lg-4">
                        <h1 className="text-white font-italic mb-2 mb-md-3">{initData.heading}</h1>
                        <h3 className="text-white text-capitalize">{initData.headingTwo}</h3>
                        <p className="text-white mt-3 mb-4">{initData.content}</p>
                        {/* Store Buttons */}
                        <div className="button-group store-buttons d-flex">
                            <a href="https://play.google.com/store/apps/details?id=com.lesoptijd.lesoptijd">
                            <img src={initData.playImg} alt="" />
                            </a>
                            <a href="https://apps.apple.com/us/app/lesoptijd/id1599937933?platform=iphone">
                            <img src={initData.appleImg} alt="" />
                            </a>
                        </div>
                        <span className="d-inline-block text-white fw-3 font-italic mt-3">{initData.headingSlug}</span>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );

}
