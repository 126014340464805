import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";

const initData = {
    preHeading: "Awesome",
    preHeadingspan: "Interface",
    heading: "Simple & Beautiful Interface",
    headingText: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.",
    headingTexttwo: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati."
}

const data = [
    {
        image: "/img/extra/20220313_083017.png"
    },
    {
        image: "/img/extra/20220313_083020.png"
    },
    {
        image: "/img/extra/20220320_150054.png"
    },
    {
        image: "/img/extra/20220313_083112.png"
    },
    {
        image: "/img/extra/20220313_083156.png"
    },

    {
        image: "/img/extra/20220313_114102.png"
    },
    {
        image: "/img/extra/20220313_114145.png"
    },
]

export default function ScreenshotSection() {
    const initData = {
        heading: stringsTranslate(words.words.simple_interface),
        headingText: "",
        headingTexttwo: ""
    }

        return (
            <section id="screenshots" className="section screenshots-area ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                    <div className="col-12 col-md-10 col-lg-7">
                        {/* Section Heading */}
                        <div className="section-heading text-center">
                        <h2 className="text-capitalize">{initData.heading}</h2>
                        {/*<p className="d-none d-sm-block mt-4">{initData.headingText}</p>*/}
                        {/*<p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>*/}
                        </div>
                    </div>
                    </div>
                    <div className="row">
                    <div className="col-12">
                        {/* App Screenshot Slider Area */}
                        <div className="app-screenshots">
                            {/* Single Screenshot Item */}
                            {data.map((item, idx) => {
                                return(
                                    <div key={`so_${idx}`} className="single-screenshot">
                                        <a href={item.image} data-fancybox="images">
                                            <img src={item.image} alt=""  width={300} className={"img-direct"} />
                                        </a>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
