import React  from 'react';
import axios from 'axios';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {
    BsAppIndicator,
    BsGlobe, FaUsersCog,
    FiSettings,
    GiProgression,
    IoCalendarOutline,
    MdOutlineFeedback
} from "react-icons/all";

const BASE_URL = "https://my-json-server.typicode.com/themeland/sapp/themeOneServiceSection";

export default function ServiceSection() {
    const initData = {
        "heading": stringsTranslate(words.words.make_your_device_manage),
        "buttonText": stringsTranslate(words.words.know_more),
        "thumbTwo": "/img/extra/lesoptijd_home_nobg.png",

        "serviceData": [
            {
                "id": 1,
                "iconClass": <FaUsersCog size={30}/>,
                "text": stringsTranslate(words.words.manage_students)
            },
            {
                "id": 2,
                "iconClass": <IoCalendarOutline size={30}/>,
                "text": stringsTranslate(words.words.manage_appointments)
            },
            {
                "id": 3,
                "iconClass": <BsGlobe size={30}/>,
                "text": stringsTranslate(words.words.multiple_languages)
            },
            {
                "id": 4,
                "iconClass": <MdOutlineFeedback size={30}/>,
                "text": stringsTranslate(words.words.give_feedback)
            },
            {
                "id": 5,
                "iconClass": <BsAppIndicator size={30}/>,
                "text": stringsTranslate(words.words.notifications)
            },
        ]
    }

        return (
            <section className="section service-area bg-inherit overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Service Text */}
                        <div className="service-text pt-4 pt-lg-0">
                        <h2 className="mb-4">{initData.heading}</h2>
                        {/* Service List */}
                        <ul className="service-list">
                            {initData.serviceData.map((item, idx) => {
                                return(
                                    <div key={`st_${idx}`}>
                                        {/* Single Service */}
                                        <li className="single-service media py-2">
                                        <div className="service-icon pr-4">
                                            <span>{item.iconClass}</span>
                                        </div>
                                        <div className="service-text text-left mt-3 media-body">
                                            <p>{item.text}</p>
                                        </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <a href="#contact" className="btn btn-bordered mt-4 scroll">{initData.buttonText}</a>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 order-1 order-lg-2 d-none d-md-block">
                        {/* Service Thumb */}
                        <div className="service-thumb mx-auto">
                            <img src={initData.thumbTwo} alt=""  width={300} />
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
