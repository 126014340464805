import React from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {appLanguage} from "../../config/constants";

export default function Header({imageData, switchLangauge}) {
    const initData = {
        element1: stringsTranslate(words.words.home),
        element2: stringsTranslate(words.words.account),
        element3:  stringsTranslate(words.words.language),
        element4: stringsTranslate(words.words.features),
        element5: stringsTranslate(words.words.app_screen),
        element6: stringsTranslate(words.words.plans),
        element7: stringsTranslate(words.words.contact)
    }

    return (
        <header className="navbar navbar-sticky navbar-expand-lg navbar-dark">
            <div className="container position-relative">
                <a className="navbar-brand" href="/">
                    <img className="navbar-brand-regular" src={imageData} alt="brand-logo" height={50} width={50}/>
                    <img className="navbar-brand-sticky" src="/lesoptijd/logo.svg" alt="sticky brand-logo" height={50} width={50}/>
                </a>
                <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"/>
                </button>
                <div className="navbar-inner">
                    {/*  Mobile Menu Toggler */}
                    <button className="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler"
                            aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"/>
                    </button>
                    <nav>
                        <ul className="navbar-nav" id="navbar-nav">
                            <li className="nav-item">
                                <a className="nav-link" href="/">{initData.element1}</a>
                            </li>
                            {/*<li className="nav-item dropdown">*/}
                            {/*    <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"*/}
                            {/*       data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">*/}
                            {/*        {initData.element2}*/}
                            {/*    </a>*/}
                            {/*    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">*/}
                            {/*        <li>*/}
                            {/*            <a className="dropdown-item" href="/login">{stringsTranslate(words.words.signin)}</a>*/}
                            {/*        </li>*/}
                            {/*        <li>*/}
                            {/*            <a className="dropdown-item" href="/signup">{stringsTranslate(words.words.sign_up)}</a>*/}
                            {/*        </li>*/}
                            {/*    </ul>*/}
                            {/*</li>*/}
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdownMenuLink"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {initData.element3}
                                </a>
                                <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                    <li>
                                        <a className="dropdown-item"  onClick={()=> {switchLangauge(appLanguage.nl)}}> {stringsTranslate(words.words.dutch)}</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item"  onClick={()=> {switchLangauge(appLanguage.en)}}> {stringsTranslate(words.words.english)}</a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" onClick={()=> {switchLangauge(appLanguage.ar)}}> {stringsTranslate(words.words.arabic)}</a>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#features">{initData.element4}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#screenshots">{initData.element5}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#pricing">{initData.element6}</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link scroll" href="#contact">{initData.element7}</a>
                            </li>

                        </ul>
                    </nav>
                </div>
            </div>
        </header>
    );
}