export const appColors = {
    primary: '#06D60C',
    secondary: '#023803',
    secondaryAlt: '#090808',
    border: '#000000',
    inactive: '#c7ced3',
    baseBackground: '#F6F6F7',
    darkBackground: 'rgba(2,56,3,0.4)',
    white: '#FFFFFF',
    light: '#A8B3BA',
    black: '#090808',
    green: '#32CD32',
    red: '#FF4500',
    transparent: 'rgba(1,214,132,0.2)',
    gray: '#505050',
}


export const appLocalStoargeValues = {
    userData: 'userData',
    loggedIn: 'loggedIn',
    language: 'language',
}

export const appUrls = {
    backend_api: 'https://lesoptijd-backend-prod.onrender.com/', //production
}

export const appRoutes = {
    home: '/',
    features: '/features',
    plans: '/plans',
    signup: '/sign-up',
    signin: '/sign-in',
    verification: '/verification',
    dashboard: '/dashboard',
    drivingInstructorFeatures: '/driving-instructor-features',
    dataIntegration: '/dataintegration',
}

export const appLanguage = {
    nl: 'nl',
    en: 'en',
    ar: 'ar',
}