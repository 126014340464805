import React  from 'react';
import axios from 'axios';


export default function FooterSection() {

    const initData={
        "image": "/lesoptijd/logo.png",
        "text": "",
        "linkText_1": "Useful Links",
        "linkText_2": "Product Help",
        "linkText_3": "Download",
        "iconList": [
            {
                "id": 1,
                "link": "facebook",
                "iconClass": "fab fa-facebook-f h3"
            },
            {
                "id": 2,
                "link": "instagram",
                "iconClass": "fab fa-instagram h3"
            },
            {
                "id": 3,
                "link": "linkedin",
                "iconClass": "fab fa-linkedin h3"
            },
            // {
            //     "id": 4,
            //     "link": "vine",
            //     "iconClass": "fab fa-vine"
            // }
        ],
        "footerList_1": [
            {
                "id": 1,
                "text": "Terms and Conditions"
            },
            {
                "id": 2,
                "text": "Disclaimer"
            },
            {
                "id": 3,
                "text": "Contact"
            },
        ],
        "footerList_2": [
            {
                "id": 1,
                "text": "FAQ"
            },
            {
                "id": 2,
                "text": "Privacy Policy"
            },
            {
                "id": 3,
                "text": "Support"
            },
            {
                "id": 4,
                "text": "Terms & Conditions"
            },
            {
                "id": 5,
                "text": "Contact"
            }
        ],
        "footerList_3": [
            {
                "id": 1,
                "image": "/img/google-play-black.png",
                "url": "https://play.google.com/store/apps/details?id=com.lesoptijd.lesoptijd"
            },
            {
                "id": 2,
                "image": "/img/app-store-black.png",
                "url": "https://apps.apple.com/us/app/lesoptijd/id1599937933?platform=iphone"
            }
        ],
        "footerList_4": [
            {
                "id": 1,
                "text": "Affiliate program"
            },
            {
                "id": 2,
                "text": "Terms & Conditions"
            },
            {
                "id": 3,
                "text": "Privacy Policy"
            },
            {
                "id": 4,
                "text": "Refund Policy"
            }
        ]
    }

        return (
            <div>
                <div className="height-emulator d-none d-lg-block" />
                <footer className="footer-area footer-fixed">
                    {/* Footer Top */}
                    <div className="footer-top ptb_100">
                        <div className="container">
                        <div className="row">
                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Logo */}
                                <a className="navbar-brand" href="/#">
                                <img className="logo" src={initData.image} alt="" height={70} width={70} />
                                </a>
                                <p className="mt-2 mb-3">{initData.text}</p>
                                {/* Social Icons */}
                                <div className="social-icons d-flex">
                                    {initData.iconList.map((item, idx) => {
                                        return(
                                            <a key={`fi_${idx}`} className={item.link} href="/coming-soon">
                                                <i className={item.iconClass} />
                                                <i className={item.iconClass} />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{initData.linkText_1}</h3>
                                <ul>
                                    {initData.footerList_1.map((item, idx) => {
                                        return(
                                            <li key={`flo_${idx}`} className="py-2"><a href="/disclaimer">{item.text}</a></li>
                                        );
                                    })}
                                </ul>
                            </div>
                            </div>
                            {/*<div className="col-12 col-sm-6 col-lg-3">*/}
                            {/*/!* Footer Items *!/*/}
                            {/*<div className="footer-items">*/}
                            {/*    /!* Footer Title *!/*/}
                            {/*    <h3 className="footer-title mb-2">{initData.linkText_2}</h3>*/}
                            {/*    <ul>*/}
                            {/*        {initData.footerList_2.map((item, idx) => {*/}
                            {/*            return(*/}
                            {/*                <li key={`flt_${idx}`} className="py-2"><a href="/#">{item.text}</a></li>*/}
                            {/*            );*/}
                            {/*        })}*/}
                            {/*    </ul>*/}
                            {/*</div>*/}
                            {/*</div>*/}
                            <div className="col-12 col-sm-6 col-lg-4">
                            {/* Footer Items */}
                            <div className="footer-items">
                                {/* Footer Title */}
                                <h3 className="footer-title mb-2">{initData.linkText_3}</h3>
                                {/* Store Buttons */}
                                <div className="button-group store-buttons store-black d-flex flex-wrap">
                                    {initData.footerList_3.map((item, idx) => {
                                        return(
                                            <a key={`flth_${idx}`} href={item.url}>
                                                <img src={item.image} alt="" />
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                    {/* Footer Bottom */}
                    <div className="footer-bottom">
                        <div className="container">
                        <div className="row">
                            <div className="col-12">
                            {/* Copyright Area */}
                            <div className="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                                {/* Copyright Left */}
                                <div className="copyright-left">© Copyrights 2022 LesOpTijd All rights reserved.</div>
                                {/* Copyright Right */}
                                {/*<div className="copyright-right">Made with <i className="fas fa-heart" /> By <a href="/#">LesOpTijd</a></div>*/}
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </footer>
            </div>
        );
    }
