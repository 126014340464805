import React from "react";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";

// importing all the pages
import Home from "../pages/home";
import Reviews from "../components/ReviewSection/Reviews";
import Pricing from "../components/PricingSection/Pricing";
import DownloadPage from "../components/DownloadSection/DownloadPage";
import SubscribePage from "../components/SubscribeSection/SubscribePage";
import ThankYou from "../components/InnerSection/ThankYou";
import ComingSoon from "../components/InnerSection/ComingSoon";
import Login from "../components/Accounts/Login";
import Signup from "../components/Accounts/Signup";
import Forgot from "../components/Accounts/Forgot";
import Faq from "../components/FaqSection/Faq";
import ErrorPage from "../components/ErrorPage/404";
import ContactPage from "../components/ContactSection/ContactPage";
import Disclaimer from "../pages/disclaimer";

export default function MyRouts() {

    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/" component={Home} />
            {/*<Route path="/reviews" component={Reviews} />*/}
            {/*<Route path="/pricing" component={Pricing} />*/}
            {/*<Route path="/download-page" component={DownloadPage} />*/}
            {/*<Route path="/subscribe-page" component={SubscribePage} />*/}
            <Route path="/thank-you" component={ThankYou} />
            <Route path="/disclaimer" component={Disclaimer} />
            <Route path="/coming-soon" component={ComingSoon} />
            {/*<Route path="/login" component={Login} />*/}
            {/*<Route path="/signup" component={Signup} />*/}
            {/*<Route path="/forgot" component={Forgot} />*/}
            {/*<Route path="/faq" component={Faq} />*/}
            <Route path="/error-page" component={ErrorPage} />
            {/*<Route path="/contact-page" component={ContactPage} />*/}
            <Redirect to='/error-page' />
          </Switch>
        </Router>
      </div>
    );
  }

