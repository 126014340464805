import React from 'react';
import Header from '../components/HeaderSection/Header';
import HeroSection from '../components/HeroSection/HeroFour';
import FeatureSection from '../components/Features/FeatureTwo';
import ServiceSection from '../components/ServiceSection/ServiceTwo';
import DiscoverSection from '../components/DiscoverSection/DiscoverTwo';
import Work from '../components/WorkSection/Work';
import FooterSection from '../components/FooterSection/Footer';
import ScreenshotSection from "../components/ScreenshotSection/ScreenshotsTwo";
import PricingSection from "../components/PricingSection/PricingTwo";
import ContactSection from "../components/ContactSection/Contact";
import Download from "../components/DownloadSection/Download";
import Partners from "../components/PartnersSection/Partners";

export default function Home() {
    return (
        <div className="homepage-4">
            {/*====== Scroll To Top Area Start ======*/}
            <div id="scrollUp" title="Scroll To Top">
                <i className="fas fa-arrow-up"/>
            </div>
            {/*====== Scroll To Top Area End ======*/}
            <div className="main">
                <HeroSection/>
                <FeatureSection/>
                <DiscoverSection/>
                <ServiceSection/>
                <Work/>
                <ScreenshotSection/>
                <PricingSection/>
                {/*<Partners/>*/}
                <Download/>
                <ContactSection/>
                <FooterSection/>
            </div>
        </div>
    );
}
