import React, { Component } from 'react';


export function DisclaimerComponent() {
    const initData = {
        "heading": "PRIVACY POLICY",
        "headingTwo": "Have questions? Look here",
        "headingText": "LesOpTijd respects the privacy of its website visitors,\n" +
            "                                    in particular their rights regarding the automatic processing of personal data.\n" +
            "                                    We have therefore formulated and implemented a policy on complete transparency\n" +
            "                                    with our customers regarding the processing of personal data, its purpose(s)\n" +
            "                                    and the possibilities to exercise your legal rights in the best possible way.\n" +
            "If you require any additional information about the protection of personal data,\n" +
            "                                    please visit the website of the Dutch Data Protection Authority\n" +
            "                                    (Autoriteit Persoonsgegevens): https://autoriteitpersoonsgegevens.nl/nl \n" +
            "Until you accept the use of cookies and other tracking devices, we will not place\n" +
            "                                    any non-anonymised analytical cookies and / or tracking cookies on your computer,\n" +
            "                                    mobile phone or tablet.\n" +
            "                                    With the continued visit of this website you accept these terms of use and you\n" +
            "                                    accept the\n" +
            "                                    use of cookies and other tracking systems, unless we have provided for another\n" +
            "                                    method of\n" +
            "                                    accepting cookies on our website. \n" +
            "The current available version of this privacy policy is the only version that applies\n" +
            "                                    while visiting our website until a new version replaces the current version.",

        "headingTexttwo": "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.",
        "faqText": "Haven't find suitable answer?",
        "faqTextLink": "Tell us what you need.",
        "faqData": [
            {
                "id": 1,
                "title": "Article 1 - Definitions",
                "content": "Website (hereinafter: \"Website\") www.LesOpTijd.nl. \n" +
                    "Party responsible for processing personal data (hereinafter: \"the controller\"):\n" +
                    "                                    LesOpTijd,\n" +
                    "                                    Amsterdam, The Netherlands, Chamber of Commerce number: 77463412"
            },
            {
                "id": 2,
                "title": "Article 2 - Access to the website",
                "content": "Access to and use of the website are strictly personal. You will refrain\n" +
                    "                                    from using the data and information\n" +
                    "                                    of this website for your own commercial, political or advertising\n" +
                    "                                    purposes, as well as for any commercial offers, in particular unsolicited electronic\n" +
                    "                                    offers."
            },
            {
                "id": 3,
                "title": "Article 3 - Website content",
                "content": "All brands, images, texts, comments, illustrations (animated) images, video images,\n" +
                    "                                    sounds and all the technical applications that can be used to operate this website\n" +
                    "                                    and more\n" +
                    "                                    generally all the components used on this website, are protected by the laws on\n" +
                    "                                    intellectual property.\n" +
                    "                                    Any reproduction, repetition, use or modification, by any means whatsoever, of all\n" +
                    "                                    or just part of it,\n" +
                    "                                    including technical applications, without the prior written permission of the\n" +
                    "                                    controller, is strictly prohibited.\n" +
                    "                                    The fact that the controller may not take immediate action against any infringement,\n" +
                    "                                    can not be considered\n" +
                    "                                    as a tacit consent, nor of a waiver of any right to prosecute the infringing party."
            },
            {
                "id": 4,
                "title": "Article 5 - Responsibilities",
                "content": "1. The controller is not liable for any failure, disturbances, difficulties\n" +
                    "                                    or interruptions in the functioning of the website, causing the (temporary)\n" +
                    "                                    inaccessibility of the website or of any\n" +
                    "                                    of its functionalities. You, yourself, are responsible for the way you seek\n" +
                    "                                    connection to our website. You need to take all\n" +
                    "                                    appropriate steps to protect your equipment and data against hazards such\n" +
                    "                                    as virus attacks on the Internet. Furthermore, you are responsible for which\n" +
                    "                                    websites you visit and what information you seek.\n" +
                    "2. The controller is not liable for any legal proceedings taken against you:\n" +
                    "                                    • because of the use of the website or services accessible via the Internet\n" +
                    "                                    • for violating the terms of this privacy policy\n" +
                    " 3. The controller is not liable for any damages that incur to you or third parties\n" +
                    "                                    or your equipment,\n" +
                    "                                    as a result of your connection to or use of\n" +
                    "                                    the website and you will refrain from any subsequent (legal) action against the\n" +
                    "                                    controller.\n" +
                    " 4. If the controller is involved in a dispute because of your\n" +
                    "                                    (ab)use of this website, he is entitled to (re)claim all subsequent damages from\n" +
                    "                                    you."
            },
            {
                "id": 5,
                "title": "Article 4 - Management of the website",
                "content": "For the purpose of proper management of the site, the controller may at any time: \n" +
                    "• suspend, interrupt, reduce or decline the access to the website for a particular\n" +
                    "                                    category of visitors\n" +
                    "• delete all information that may disrupt the functioning of the website or\n" +
                    "                                    conflicts with national or international laws or is contrary to internet etiquette\n" +
                    "• make the website temporarily unavailable in order to perform updates"
            },
            {
                "id": 6,
                "title": "Article 6 - Collection of data",
                "content": "1. Your personal data will be collected by LesOpTijd \n" +
                    "2. Personal data means any information relating to an identified or identifiable\n" +
                    "                                    natural person (‘data subject’).\n" +
                    " 3. An identifiable natural person is one who can be identified, directly or\n" +
                    "                                    indirectly, in\n" +
                    "                                    particular by reference to an identifier such as a name, an identification number,\n" +
                    "                                    location data,\n" +
                    "                                    an online identifier or to one or more factors specific to the physical,\n" +
                    "                                    physiological, genetic, mental, economic, cultural or social identity of that\n" +
                    "                                    natural person.\n" +
                    " 4. The personal data that are collected on the website are used mainly by the\n" +
                    "                                    collector in order to maintain a (commercial) relationship\n" +
                    "                                    with you and if applicable in order to process your orders. They are recorded in an\n" +
                    "                                    (electronic) register.\n"
            },
            {
                "id": 7,
                "title": "Article 7 - Your rights regarding information",
                "content": " 1. Pursuant to Article 13 paragraph 2 sub b GDPR each data subject has the right to\n" +
                    "                                    information on and access to,\n" +
                    "                                    and rectification, erasure and restriction of processing of his personal data,\n" +
                    "                                    as well as the right to object to the processing and the right to data\n" +
                    "                                    portability.\n" +
                    " 2. You can exercise these rights by contacting us at info@lesoptijd.nl.\n" +
                    " 3. Each request must be accompanied by a copy of a valid ID,\n" +
                    "                                    on which you put your signature and state the address where we can contact you.\n" +
                    "4. Within one month of the submitted request, you will receive an answer from us.\n" +
                    " 5. Depending on the complexity and the number of the requests this period may be\n" +
                    "                                    extended to two months."
            },
            {
                "id": 8,
                "title": "Article 8 - Legal obligations",
                "content": "1. In case of infringement of any law or regulation, of which a visitor is suspected\n" +
                    "                                    and for which\n" +
                    "                                    the authorities require the personal data collected by the collector, they will be\n" +
                    "                                    provided to them after\n" +
                    "                                    an explicit and reasoned request of those authorities, after which these personal\n" +
                    "                                    data\n" +
                    "                                    do not fall anymore under the protection of the provisions of this Privacy\n" +
                    "                                    policy.\n" +
                    " 2. If any information is necessary in order to obtain access to certain features of\n" +
                    "                                    the website,\n" +
                    "                                    the controller will indicate the mandatory nature of this information when\n" +
                    "                                    requesting these data."
            },
            {
                "id": 9,
                "title": "Article 9 - Collected data and commercial offers",
                "content": "1. You may receive commercial offers from the collector. If you do not wish to\n" +
                    "                                    receive them (anymore),\n" +
                    "                                    please send us an email to the following address: info@LesOpTijd.nl.\n" +
                    "2. Your personal data will not be used by our partners for commercial purposes.\n" +
                    "3. If you encounter any personal data from other data subjects while visiting our\n" +
                    "                                    website, you are\n" +
                    "                                    to refrain from collection, any unauthorized use or any other act that constitutes\n" +
                    "                                    an infringement of the privacy of the data subject(s)\n" +
                    "                                    in question. The collector is not responsible in these circumstances."
            },
            {
                "id": 10,
                "title": "Article 10 - Data retention",
                "content": "The collected data are used and retained for the duration determined by law."
            },
            {
                "id": 11,
                "title": "Article 11 - Cookies",
                "content": "1. A cookie is a small text file placed on the hard drive of your electronic device\n" +
                    "                                    upon visiting our website.\n" +
                    "                                    A cookie contains data so you can be recognized as a visitor when you are visiting\n" +
                    "                                    our website. It enables us to\n" +
                    "                                    adjust to your needs and it facilitates you to log in on our website. When you visit\n" +
                    "                                    our website, we inform you about\n" +
                    "                                    the use of cookies. By continuing to use our website you accept its use, unless we\n" +
                    "                                    ask permission by other means. Your consent is valid for a period of thirteen\n" +
                    "                                    months.\n" +
                    " 2. We use the following types of cookies on our website:\n" +
                    " 3. Specifically, we use the following cookies on our website:\n" +
                    "                                    No other cookies\n" +
                    " 4. When you visit our website, cookies from the controller and / or third parties\n" +
                    "                                    may be installed on your equipment.\n" +
                    "5. For more information about using, managing and deleting cookies for each\n" +
                    "                                    electronic device, we invite you to consult the following link:\n" +
                    "                                    https://autoriteitpersoonsgegevens.nl/nl/onderwerpen/internet-telefoon-tv-en-post /\n" +
                    "                                    cookies # faq"
            },
            {
                "id": 12,
                "title": "Article 12 - Imagery and products offered",
                "content": "You cannot derive any rights from the imagery that accompanies any offered product on\n" +
                    "                                    our website."
            },
            {
                "id": 13,
                "title": "Article 13 - Applicable Law",
                "content": "These conditions are governed by Dutch law. The court in the district where the\n" +
                    "                                    collector has its place of business has the sole jurisdiction if any dispute\n" +
                    "                                    regarding these conditions may arise, save when a legal exception applies."
            },
            {
                "id": 14,
                "title": "Article 14 - Contact",
                "content": "For questions, product information or information about the website itself, please\n" +
                    "                                    contact: Team LesOpTijd, info@lesoptijd.nl ."
            }
        ]
    }

        return (
            <section className="section faq-area style-two ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2 className="text-capitalize">{initData.heading}</h2>
                                <p className="d-none d-sm-block mt-4">{initData.headingText}</p>
                                <p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12">
                            {/* FAQ Content */}
                            <div className="faq-content">
                                {/* sApp Accordion */}
                                <div className="accordion" id="sApp-accordion">
                                    <div className="row justify-content-center">
                                        <div className="col-12 col-md-6">
                                            {/* Single Card */}
                                            {initData.faqData.map((item, idx) => {
                                                return(
                                                    <div key={`fo_${idx}`} className="card border-0">
                                                        {/* Card Header */}
                                                        <div className="card-header bg-inherit border-0 p-0">
                                                            <h2 className="mb-0">
                                                                <button className="btn px-0 py-2" type="button">
                                                                    {item.title}
                                                                </button>
                                                            </h2>
                                                        </div>
                                                        {/* Card Body */}
                                                        <div className="card-body px-0 py-3">
                                                            {item.content}
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                    <div className="row justify-content-center">
                                        <p className="text-body text-center pt-4 fw-5">{initData.faqText} <a href="/#">{initData.faqTextLink}</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }

