import React  from 'react';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {
    BsAppIndicator,
    BsGlobe,
    FiSettings,
    GiProgression,
    IoCalendarOutline, IoLogoBuffer,
    MdOutlineFeedback
} from "react-icons/all";




export default function DiscoverSection() {
    const initData = {
        "heading": stringsTranslate(words.words.work_faster),
        "thumbOne": "/img/extra/lesoptijd_dashboard_nobg.png",
        "thumbTwo": "/img/extra/lesoptijd_dashboard_nobg.png",
        "discoverData": [
            {
                "id": 1,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_staff)
            },
            {
                "id": 2,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_students)
            },
            {
                "id": 3,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_products)
            },
            {
                "id": 4,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_packages)
            },
            {
                "id": 5,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_appointments)
            },
            {
                "id": 6,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.multiple_languages)
            },
            {
                "id": 7,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.notifications)
            },
            {
                "id": 8,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.progress_overview)
            },
            {
                "id": 8,
                "iconClass": "fas fa-check",
                "text": stringsTranslate(words.words.manage_announcement)
            }
        ],
        "discoverIcon": [
            {
                "id": 1,
                "iconClass": <BsAppIndicator size={30}/>,
            },
            {
                "id": 2,
                "iconClass": <BsGlobe size={30}/>,
            },
            {
                "id": 3,
                "iconClass": <IoCalendarOutline size={30}/>,
            },
            {
                "id": 4,
                "iconClass": <IoLogoBuffer size={30}/>,
            },
            {
                "id": 5,
                "iconClass": <GiProgression size={30}/>,
            },
            {
                "id": 6,
                "iconClass": <FiSettings size={30}/>,
            },
        ]
    }

        return (
            <section className="section discover-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                    <div className="col-12 col-lg-6 order-2 order-lg-1">
                        {/* Discover Thumb */}
                        <div className="service-thumb discover-thumb mx-auto text-center mt-5 mt-lg-0">
                            <img src={initData.thumbTwo} alt=""  width={300} />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 order-1 order-lg-2">
                        {/* Discover Text */}
                        <div className="discover-text px-0 px-lg-4 pt-4 pt-lg-0">
                        <h2 className="pb-4">{initData.heading}</h2>
                        {/* Check List */}
                        <ul className="check-list">
                            {initData.discoverData.map((item, idx) => {
                                return(
                                    <div key={`dt_${idx}`}>
                                        <li className="py-2">
                                            {/* List Box */}
                                            <div className="list-box media">
                                                <span className="icon align-self-center"><i className={item.iconClass} /></span>
                                                <span className="media-body pl-2">{item.text}</span>
                                            </div>
                                        </li>
                                    </div>
                                );
                            })}
                        </ul>
                        <div className="icon-box d-flex mt-3">
                        {initData.discoverIcon.map((item, idx) => {
                                return(
                                    <div key={`il_${idx}`} className="service-icon pr-3">
                                        <span>{item.iconClass}</span>
                                    </div>
                                );
                            })}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }

