import React, { Component } from 'react';
import axios from 'axios';
import {stringsTranslate} from "../../locales/CustomTranslater";
import {words} from "../../locales/Words";
import {
    BsAppIndicator,
    BsGlobe,
    FaUsersCog, GiScooter,
    IoCalendarOutline,
    MdOutlineFeedback,
    MdOutlinePedalBike, RiEBike2Line
} from "react-icons/all";


export default function PricingSection() {
    const initData = {
        "heading": stringsTranslate(words.words.unlock_full_power),
        "headingText": "",
        "headingTexttwo": "",
        "pricingData": [
            {
                "id": 1,
                "planImage":<MdOutlinePedalBike size={60}/>,
                "planTitle": "Basic",
                "priceSub": "€",
                "planPrice": "25",
                "planBtn": stringsTranslate(words.words.download_now),
                features: [
                    stringsTranslate(words.words.manage_staff),
                    stringsTranslate(words.words.manage_students),
                    stringsTranslate(words.words.manage_products),
                    stringsTranslate(words.words.manage_packages),
                    stringsTranslate(words.words.manage_appointments),
                    stringsTranslate(words.words.multiple_languages),
                ]
            },
            {
                "id": 2,
                "planImage":<GiScooter size={60}/>,
                "planTitle": "Pro",
                "priceSub": "€",
                "planPrice": "32",
                "planBtn": stringsTranslate(words.words.download_now),
                features: [
                    stringsTranslate(words.words.manage_staff),
                    stringsTranslate(words.words.manage_students),
                    stringsTranslate(words.words.manage_products),
                    stringsTranslate(words.words.manage_packages),
                    stringsTranslate(words.words.manage_appointments),
                    stringsTranslate(words.words.multiple_languages),
                    stringsTranslate(words.words.smart_notifications),
                    stringsTranslate(words.words.give_feedback),
                    stringsTranslate(words.words.manage_finances),
                    stringsTranslate(words.words.student_progress),
                ]
            }
        ]
    }

        return (
            <section id="pricing" className="section price-plan-area bg-gray overflow-hidden ptb_100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-lg-7">
                            {/* Section Heading */}
                            <div className="section-heading text-center">
                                <h2>{initData.heading}</h2>
                                {/*<p className="d-none d-sm-block mt-4">{initData.headingText}</p>*/}
                                {/*<p className="d-block d-sm-none mt-4">{initData.headingTexttwo}</p>*/}
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-lg-8">
                            <div className="row price-plan-wrapper">
                                {initData.pricingData.map((item, idx) => {
                                    return(
                                        <div key={`p_${idx}`} className="col-12 col-md-6 mt-4">
                                            {/* Single Price Plan */}
                                            <div className="single-price-plan text-center p-5">
                                                {/* Plan Thumb */}
                                                <div className="plan-thumb">
                                                    {item.planImage}
                                                </div>
                                                {/* Plan Title */}
                                                <div className="plan-title my-2 my-sm-3">
                                                    <h3 className="text-uppercase">{item.planTitle}</h3>
                                                </div>
                                                {/* Plan Price */}
                                                <div className="plan-price pb-2 pb-sm-3">
                                                    {/*<h1 className="color-primary"><small className="fw-7">{item.priceSub}</small>{item.planPrice}</h1>*/}
                                                </div>
                                                {/* Plan Description */}
                                                <div className="plan-description">
                                                    <ul className="plan-features">
                                                        {item.features.map((item, id) => {
                                                            return(
                                                                <li key={id} className="border-top py-3">{item}</li>
                                                            )
                                                        })}
                                                    </ul>
                                                </div>
                                                {/* Plan Button */}
                                                <div className="plan-button">
                                                    <a href="#contact" className="btn mt-4 nav-link scroll">{item.planBtn}</a>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
